import { getFirebaseBackend } from "@/authUtils";
import * as Sentry from "@sentry/vue";
import { captureMessage } from "@sentry/vue";
import { BlockMotorSpecsInstance } from "./BlockMotorSpecs";

/** Fires a proactive Sentry log including the VRM called 'MotorSpecsApiError' fingerprinted by our release number */
export function fireMotorSpecsApiErrorEvent(state: BlockMotorSpecsInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsApiError");
  });
}

/** Fires a proactive Sentry log including the VRM called 'MotorSpecsVehicleNotFound' release number */
export function fireMotorSpecsVehicleNotFoundEvent(state: BlockMotorSpecsInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsVehicleNotFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'MotorSpecsVehicleFound' release number */
export function fireMotorSpecsVehicleFoundEvent(state: BlockMotorSpecsInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsVehicleFound");
  });
}

/** Fires a proactive Sentry log including the VRM called 'MotorSpecsVrmEntered' release number */
export function fireMotorSpecsVrmEntered(state: BlockMotorSpecsInstance) {
  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("vrm", state.vrm);
    scope.setTag("funnelId", state.funnelId);
    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsVrmEntered");
  });
}

/** Fires a proactive Sentry log including the VRM called 'MotorSpecsVehicleNotFound' release number */
export function fireMotorSpecsNotQualifiedEvent(state: BlockMotorSpecsInstance) {
  const vrm = tryParseJson(state.vrm);
  const make = tryParseJson(state.vehicle.make);
  const fuelType = tryParseJson(state.vehicle.fuelType);
  const dateFirstRegistered = tryParseJson(state.vehicle.dateFirstRegistered);
  const emCode = tryParseJson(state.vehicle.emCode);

  Sentry.withScope((scope) => {
    scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
    scope.setTag("funnelId", state.funnelId);
    scope.setTag("vrm", vrm);
    scope.setTag("make", make);
    scope.setTag("fuelType", fuelType);
    scope.setTag("dateFirstRegistered", dateFirstRegistered);
    scope.setTag("emCode", emCode);

    scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER]);
    captureMessage("MotorSpecsVehicleNotQualified");
  });
}

function tryParseJson<T>(json: T): T | string {
  try {
    return JSON.parse(JSON.stringify(json));
  } catch (e) {
    return "";
  }
}
