import { navigationComputed, eventComputed, themesComputed, formComputed } from "@/state/helpers";
//import { BlockMotorSpecsInstance } from "./BlockMotorSpecs";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(themesComputed as Record<keyof typeof themesComputed, any>),
    ...(formComputed as Record<keyof typeof formComputed, any>),
  };
}
export const computed = {
  /*  */
};
