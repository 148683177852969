<script lang="ts">
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";

import { Props } from "@/types";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const inputPropsValue: Props.BlockFileInput = inputProps({
  answerType: "string",
  extraProps: ["placeholder"],
  placeholder: "Click to upload",
});

import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";

import { formComputed, themesComputed } from "@/state/helpers";
import { getFirebaseBackend } from "@/authUtils";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { FileMeta } from "@claimsgate/core-types";
import BlockInput from "@/components/shared/blocks/input.vue";
const MAX_UPLOAD_MB = 30;

export default Vue.extend({
  name: "BlockMultiFileInput",
  components: { BlockInputWrapper, BlockInput },
  props: {
    ...inputPropsValue,
    multiple: {
      type: Boolean,
      required: true,
      default: false,
      description: "When true, will allow multiple files to be selected",
      allowVariable: false,
    } as Props.Bool,
    answer: {
      required: false,
      allowVariable: false,
      description: "The file object",
    } as Props.CGPropExtras<any>,
    required: {
      type: Boolean,
      required: true,
      default: true,
      description: "When true, will require the user to upload a file",
      allowVariable: false,
    } as Props.Bool,
  },
  data() {
    return {
      file: null,
      files: [{ id: uuidv4(), file: null }] as Array<{ id: string; file: File | null }>,
      fileNames: [] as Array<string>,
      fileFocused: false,
      uiMessages: {
        uploadToBig: "File upload size is too large, must be less than 30MB",
        selectFile: "Each file input needs a selected file. Please check all inputs or remove unused ones.",
      },
      localInvalidFeedback: "",
      userService: getUserHelper(),
      previousFiles: undefined as Array<FileMeta>,
    };
  },
  watch: {
    /** Listens for changes to the invalid feedback and updates the local value */
    invalidFeedback: {
      handler(newVal) {
        // If the answer is empty, set the invalid feedback to the select file message
        if (!this.answer) {
          this.localInvalidFeedback = this.uiMessages.selectFile;
          return;
        }

        // Otherwise, set the invalid feedback to the new value
        this.localInvalidFeedback = newVal;
      },
      immediate: true,
    },

    /**
     * Updates the file if the user has uploaded a new file
     */
    files: {
      async handler(newFiles, oldFiles) {
        this.files = newFiles;
        window.console.log(">>> Selected new, old", newFiles, oldFiles);
        if (newFiles) {
          this.$emit("update:state", null);
          this.fileNames = newFiles.map((el) => el.file?.name);

          if (Array.isArray(newFiles)) {
            // Process a multiple file upload
            const parsedFiles = (newFiles as Array<{ id: string; file: File | null }>)
              .map((el) => {
                if (!el.file) {
                  return null;
                }

                // Check the size of each file doesn't exceeed the maximum
                const mb = el.file.size / 1024 / 1024;
                if (mb > MAX_UPLOAD_MB) {
                  this.$emit("update:state", false);
                  this.$emit("update:invalidFeedback", this.uiMessages.uploadToBig);
                  this.reset();
                  return null;
                } else {
                  // Set a progress marker for each file
                  console.log("[FILE] set progress for ", el.file?.name);
                  this.$store.dispatch("form/setFileUploadProgress", { fileName: el?.file?.name, progress: 0 });
                  return el.file;
                }
              })
              .filter((el) => el !== null);

            if (
              (parsedFiles.length === newFiles.length && newFiles.length > 0) ||
              (!this.required && parsedFiles.length !== 0)
            ) {
              // All files are valid, upload them
              console.log("[FILE] All files valid, uploading", parsedFiles);
              this.$emit("update:answer", { file: parsedFiles });
            } else {
              console.log("[FILE] Invalid file, resetting", this.answer);

              this.$emit("update:answer", "");
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    changeFiles() {
      this.previousFiles = undefined;
      this.$emit("update:answer", "");
    },
    addFileInput() {
      this.files.push({ id: uuidv4(), file: null });
    },

    async deleteFile(index: number) {
      const newFiles = this.files;
      const fileNames = this.fileNames;

      // Remove the file from the array
      newFiles.splice(index, 1);
      fileNames.splice(index, 1);

      this.$set(this, "files", newFiles);
      this.$set(this, "fileNames", fileNames);

      // Reset the specific file input
      // @ts-ignore
      this.$refs[`file-input-${index}`]?.[0]?.reset();
    },

    reset() {
      // Iterate over the files and reset each one
      this.files.forEach((_, index) => {
        // @ts-ignore
        this.$refs[`file-input-${index}`]?.[0]?.reset();
      });
    },
  },
  async mounted() {
    const userId = this.userService.getUserId();
    if (Array.isArray(this.answer)) {
      this.previousFiles = (
        await Promise.all(
          this.answer.map(async (file) => {
            const fileMeta = (
              await getFirebaseBackend()
                .firestore()
                .collection("users")
                .doc(userId)
                .collection("files")
                .doc(file.id)
                .get()
            ).data() as FileMeta;

            if (!fileMeta || !fileMeta.path) {
              return null;
            }
            return fileMeta;
          })
        )
      ).filter((file) => file !== null);
    }
  },

  beforeDestroy() {
    // Remove all of the file refs on destroy
    // @ts-ignore
    this.files.forEach((_, index) => {
      // @ts-ignore
      this.$refs[`file-input-${index}`]?.[0]?.reset();
    });
  },
  computed: {
    ...themesComputed,
    ...formComputed,

    /**@this { { answer : {file: File}, fileUploadProgress}} @type { () => {fileName: string, progress: number} } */
    fileUploadProgressForCurrentFileName(): { fileName: string; progress: number } {
      // Passing the current file name to the getter retrieves the relevant object containing current progress
      if (this.answer?.file?.name) {
        return this.fileUploadProgress(this.answer?.file?.name) ?? {};
      } else {
        return { progress: 0, fileName: "" };
      }
    },

    /** @type { () => string} @this { { answer : {file: File}, fileUploadProgress}}*/
    fileUploadProgressPercent(): string {
      const integer = this.fileUploadProgress(this.answer?.file?.name)?.progress;
      const percentString = `${(Math.round(integer * 100) / 100).toString()} %`;
      return percentString;
    },

    showOverlay(): boolean {
      const singleFileUploading = !!(
        this.answer?.file &&
        this.answer?.file.name &&
        this.fileUploadProgressForCurrentFileName &&
        this.fileUploadProgressForCurrentFileName.progress
      );

      const multipleFilesUploading = !!(
        this.answer?.file &&
        Array.isArray(this.answer?.file) &&
        this.answer?.file.length > 0 &&
        this.answer?.file.some((file: File) => {
          console.log("[FILE]multipleFilesUploading", this.fileUploadProgress(file.name));

          return this.fileUploadProgress(file.name)?.progress;
        })
      );

      return singleFileUploading || multipleFilesUploading;
    },
  },
});
</script>
<style>
/** Custom file input padding causes y-overflow, hiding it
Credit: https://blog.hubspot.com/website/hide-scrollbar-css
*/
label.custom-file-label {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

label.custom-file-label::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.custom-file-label {
  border: 2px solid #ced4da !important;
}
.custom-file-input:focus ~ label.custom-file-label {
  border: 2px solid var(--primary) !important;
}
.custom-file-input.is-invalid ~ .custom-file-label {
  box-shadow: none !important;
  border-color: #f14646 !important;
}
.custom-file-label.is-valid:focus ~ label.custom-file-label {
  box-shadow: none !important;
  border: 2px solid #23bd85 !important;
}
.custom-file-input.is-valid ~ label.custom-file-label {
  box-shadow: none !important;
  border: 2px solid #23bd85 !important;
}
</style>
<template>
  <div>
    <template v-if="previousFiles">
      <BlockInputWrapper
        :info="info"
        :padding="padding"
        :label="label"
        :invalidFeedback="localInvalidFeedback"
        :state="state"
      >
        <BlockInput
          v-for="(file, index) in previousFiles"
          :key="index"
          v-bind="{ disabled: true, answer: file.name, inputType: 'text' }"
        />
        <div>
          <b-button size="md" variant="link" class="mt-1 text-secondary" @click="changeFiles()">
            Click here to change files
          </b-button>
        </div>
      </BlockInputWrapper>
    </template>
    <template v-else>
      <BlockInputWrapper
        :info="info"
        :padding="padding"
        :label="label"
        :invalidFeedback="localInvalidFeedback"
        :state="state"
      >
        <b-overlay
          :show="
            !!(
              answer?.file &&
              answer?.file.name &&
              fileUploadProgressForCurrentFileName &&
              fileUploadProgressForCurrentFileName.progress
            )
          "
        >
          <template v-for="(file, index) in files">
            <div class="d-flex mt-2" :key="file.id">
              <!-- Name:{{ file?.name }} -->
              <b-form-file
                v-model="files[index].file"
                :size="theme.size"
                :state="state"
                :accept="null"
                :multiple="false"
                :disabled="disabled"
                @change="$emit('update:state', null)"
                :ref="`file-input-${index}`"
              >
                <template slot="file-name"> {{ fileNames[index] }} </template>
              </b-form-file>

              <b-button
                v-if="files.length > 1"
                size="lg"
                class="mb-0 float-right text-md text-grey-600 p-0 hover-red-600"
                style="background: transparent; border: none"
                @click="deleteFile(index)"
                ><b-icon icon="x" scale="2" class="mx-3 icon"></b-icon>
              </b-button>
            </div>
          </template>
          <template #overlay>
            <div class="text-center">
              <b-spinner class="spinner-border-sm m-1"></b-spinner>
              {{ fileUploadProgressPercent }}
            </div>
          </template>
        </b-overlay>
      </BlockInputWrapper>
      <template v-if="multiple">
        <b-button size="lg" variant="link" class="mt-2" @click="addFileInput()">
          <b-icon icon="plus"></b-icon>
          {{ $t("blocks.multiFile.addFile") }}
        </b-button>
      </template>
    </template>
  </div>
</template>
