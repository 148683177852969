import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";

import { BlockInstanceProps, Props } from "@/types";
import { computed, mappedComputed } from "./computed";

export { methods, getProps, getData, computed, mappedComputed };

export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export type Data = ReturnType<typeof getData>;
type Props = BlockInstanceProps<ReturnType<typeof getProps>>;

export type manualVehicleInstance = Computed & Props & Data & Vue;
