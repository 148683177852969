<script lang="ts">
import Vue from "vue";
import BlockMultiFileInput from "@/components/shared/blocks/multiFileInput.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { StorageService } from "@/helpers/ClaimsGate/StorageService";
import { Fragment } from "vue-frag";
import { FileMeta } from "@claimsgate/core-types";
import { getFirebaseBackend } from "@/authUtils";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { eventComputed } from "@/state/helpers";
import { mapDocumentType, parseFiles, uploadToTemp } from "./methods";
export default Vue.extend({
  name: "YotiManualIdUpload",
  components: { BlockMultiFileInput, Fragment, BlockSingleSelect, BlockButton },
  props: {
    padding: {
      type: String,
      required: false,
      default: "20",
    },
  },
  computed: {
    ...eventComputed,
  },
  data() {
    return {
      blockState: {
        documentTypeChoice: null,
        fileInput: null,
      },
      blockAnswer: {
        documentTypeChoice: null,
        fileInput: null,
      },
      isLoading: false,
      isUploadError: false,
      db: getFirebaseBackend().firestore(),
      userHelper: getUserHelper(),
      storageService: new StorageService(),
      userDataService: undefined as UserDataService,
      claimId: this.$route.params.claimId,
      funnelId: this.$route.params.funnelId,
    };
  },
  mounted() {
    this.userDataService = new UserDataService(this.userHelper.getUserId());
  },
  methods: {
    parseFiles(files: File[]) {
      return parseFiles(files);
    },

    mapDocumentType(type: "Drivers License" | "Passport") {
      return mapDocumentType(type);
    },
    async uploadToTemp(files: Array<any>): Promise<FileMeta[]> {
      return uploadToTemp(files, this.userHelper.getUserId(), this.storageService);
    },
    async submit() {
      try {
        this.blockState.fileInput = null;
        this.blockState.fileInput = null;
        this.isLoading = true;

        if (!this.blockAnswer.documentTypeChoice) {
          this.blockState.documentTypeChoice = false;
          this.isLoading = false;
          return;
        } else {
          this.blockState.documentTypeChoice = true;
        }

        if (!this.blockAnswer.fileInput?.file?.length) {
          this.blockState.fileInput = false;
          this.isLoading = false;
          return;
        } else {
          this.blockState.fileInput = true;
        }

        const files = this.parseFiles(this.blockAnswer.fileInput.file);

        const tempStorageFileMetas = await this.uploadToTemp(files);

        console.log(tempStorageFileMetas);

        const createManualVerification = getFirebaseBackend()
          .firebaseFunctions()
          .httpsCallable("createManualVerification");

        const response = await createManualVerification({
          userId: this.userHelper.getUserId(),
          claimId: this.claimId,
          funnelId: this.funnelId,
          fileMetas: tempStorageFileMetas,
          documentType: this.mapDocumentType(this.blockAnswer.documentTypeChoice),
        });

        if (response.data?.error) {
          this.isLoading = false;
          this.isUploadError = true;
          return;
        }

        await this.userDataService.refresh();

        console.log(">> YOTI Emitting submit");
        // Maybe not the best ui, possible needs a timeout to read message/ allow user to submit button
        this.$store.dispatch("events/fire", { name: this.eventValues.next });

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.isUploadError = true;
      }
    },
  },
});
</script>
<template>
  <Fragment>
    <BlockSingleSelect
      :options="['Drivers License', 'Passport']"
      :answer.sync="blockAnswer.documentTypeChoice"
      :label="$t('blocks.yotiManualIdUpload.selectDocumentType')"
      :padding="padding"
      :state="blockState.documentTypeChoice"
      :invalidFeedback="$t('blocks.yotiManualIdUpload.thisFieldIsRequired')"
    />

    <BlockMultiFileInput
      :padding="padding"
      :label="$t('blocks.yotiManualIdUpload.uploadIdentityDocument')"
      :info="$t('blocks.yotiManualIdUpload.uploadMustBeClear')"
      :invalidFeedback="$t('blocks.yotiManualIdUpload.thisFieldIsRequired')"
      :state="blockState.documentTypeChoice"
      :answer.sync="blockAnswer.fileInput"
      :required="true"
      :multiple="true"
    ></BlockMultiFileInput>

    <BlockButton :submitButtonDefault="false" @clicked="submit()" :isProcessing="isLoading" :padding="padding" />
    <div v-if="isUploadError">
      <p class="text-danger mt-2">{{ $t("blocks.yotiManualIdUpload.errorUploading") }}</p>
    </div>
  </Fragment>
</template>
